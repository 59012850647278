body, html, #root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

.main {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  justify-content: flex-start;
}

.emulator-frame {
  width: 100%;
  height: 100%;
  border: none;
}

.btn-sm {
  --bs-btn-padding-y: 0.05rem;
}

.progress-sm {
  width: 0;
  height: 1px;
  animation: 1s linear infinite progress-sm;
  align-self: center;
  background-color: var(--bs-info);
  position: absolute;
  z-index: 1;
  opacity: 25%;
}

@keyframes progress-sm {
  0% {
    width: 0;
    background-color: var(--bs-info);
  }
  100% {
    width: 100%;
    background-color: var(--bs-success);
  }
}